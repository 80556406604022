import { resetAudioSlice } from "../../../redux/audioSlice";
import { resetChooseAlbumSlice } from "../../../redux/chooseAlbumSlice";
import { resetEmailSlice } from "../../../redux/emailSlice";
import { resetExportMapSlice } from "../../../redux/exportMapSlice";
import { resetExportTuneSlice } from "../../../redux/exportTuneSlice";
import { resetGameOfLifeSlice } from "../../../redux/gameOfLifeSlice";
import { resetMapSlice } from "../../../redux/mapSlice";
import { resetMapVisualizationSlice } from "../../../redux/mapVisualizationSlice";
import { resetMetaSlice } from "../../../redux/metaSlice";
import { resetSearchAlbumsSlice } from "../../../redux/searchAlbumsSlice";
import { resetSpotifyAuthenticationSlice } from "../../../redux/spotifyAuthenticationSlice";
import { resetTuneFeatureSlice } from "../../../redux/tuneFeatureSlice";

export function resetAllSlices(dispatch: any) {
  dispatch(resetMapSlice());
  dispatch(resetExportMapSlice());
  dispatch(resetMetaSlice());
  dispatch(resetSpotifyAuthenticationSlice());
  dispatch(resetAudioSlice());
  dispatch(resetSearchAlbumsSlice());
  dispatch(resetChooseAlbumSlice());
  dispatch(resetTuneFeatureSlice());
  dispatch(resetExportTuneSlice());
  dispatch(resetEmailSlice());
  dispatch(resetMapVisualizationSlice());
  dispatch(resetGameOfLifeSlice());
}
