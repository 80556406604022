import { lazy } from "react";
import { Provider } from "react-redux";
import { createBrowserRouter, Navigate } from "react-router-dom";
import ForEmployers from "../components/ForEmployers/ForEmployers";
import { viewLicenseAgreementUrlSuffix } from "../components/Services/ServiceConstants";
import { store } from "../redux/store";
import AppWrappers from "./AppWrappers";
import CommonSuspense from "./CommonSuspense";
import DefaultErrorBoundary from "./ErrorBoundaries/DefaultErrorBoundary";
import Mercy from "./Other/Mercy";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { recaptchaSiteKey } from "../shared/Constants/GoogleKeys";

// Lazy loaded components
const BlogPost = lazy(
  () =>
    import("../components/Blogs/BlogPost") as Promise<{
      default: React.ComponentType<any>;
    }>
);
const Blogs = lazy(() => import("../components/Blogs/Blogs"));
const Contact = lazy(() => import("../components/Contact/Contact"));
const Credits = lazy(() => import("../components/Credits/Credits"));
const Eula = lazy(() => import("../components/Eula/Eula"));
const GitHub = lazy(() => import("../components/GitHub/GitHub"));
const Home = lazy(() => import("../components/Home/Home"));
const LinkedInRedirector = lazy(
  () => import("../components/LinkedIn/LinkedInRedirector")
);
const MapboxMap = lazy(() => import("../components/Maps/Mapbox/MapboxMap"));
const NotFound = lazy(() => import("../components/NotFound/NotFound"));
const Tasks = lazy(() => import("../components/TaskList/Tasks"));
const Services = lazy(() => import("../components/Services/Services"));
const SpotifyAuthenticationCallback = lazy(
  () =>
    import(
      "../components/TunePosters/Components/Spotify/SpotifyAuthenticationCallback"
    )
);
const WidgetContainer = lazy(
  () =>
    import("../components/Widgets/WidgetContainer") as Promise<{
      default: React.ComponentType<any>;
    }>
);
const Widgets = lazy(() => import("../components/Widgets/Widgets"));
const LicenseAgreementPreview = lazy(
  () => import("../components/Services/LicenseAgreementPreview")
);

export const router = createBrowserRouter([
  {
    path: "/",
    element: <AppWrappers />,
    errorElement: (
      <Provider store={store}>
        <DefaultErrorBoundary />
      </Provider>
    ),
    children: [
      {
        path: "/",
        element: <Navigate to="/home" replace />,
      },
      {
        path: "/home",
        element: (
          <CommonSuspense>
            <Home />
          </CommonSuspense>
        ),
      },
      {
        path: "/services",
        element: (
          <CommonSuspense>
            <Services />
          </CommonSuspense>
        ),
        children: [
          {
            path: `/services/${viewLicenseAgreementUrlSuffix}`,
            element: (
              <CommonSuspense>
                <LicenseAgreementPreview />
              </CommonSuspense>
            ),
          },
        ],
      },
      {
        path: "/widgets",
        element: (
          <CommonSuspense>
            <Widgets />
          </CommonSuspense>
        ),
      },
      {
        path: "/widgets/:widgetId",
        element: (
          <CommonSuspense>
            <WidgetContainer />
          </CommonSuspense>
        ),
      },
      {
        path: "/eula",
        element: (
          <CommonSuspense>
            <Eula />
          </CommonSuspense>
        ),
      },
      {
        path: "/blogs",
        element: (
          <CommonSuspense>
            <Blogs />
          </CommonSuspense>
        ),
      },
      {
        path: "/blogs/:blogId",
        element: (
          <CommonSuspense>
            <BlogPost />
          </CommonSuspense>
        ),
      },
      {
        path: "/contact",
        element: (
          <CommonSuspense>
            <Contact />
          </CommonSuspense>
        ),
      },
      {
        path: "/credits",
        element: (
          <CommonSuspense>
            <Credits />
          </CommonSuspense>
        ),
      },
      {
        path: "/github",
        element: (
          <CommonSuspense>
            <GitHub />
          </CommonSuspense>
        ),
      },
      {
        path: "/map",
        element: (
          <CommonSuspense>
            <MapboxMap />
          </CommonSuspense>
        ),
      },
      {
        path: "/for-employers",
        element: (
          <CommonSuspense>
            <ForEmployers />
          </CommonSuspense>
        ),
      },
      {
        path: "/spotify-authentication-callback",
        element: (
          <CommonSuspense>
            <SpotifyAuthenticationCallback />
          </CommonSuspense>
        ),
      },
      {
        path: "/linkedin",
        element: (
          <CommonSuspense>
            <LinkedInRedirector />
          </CommonSuspense>
        ),
      },
      {
        path: "/tasks",
        element: (
          <CommonSuspense>
            <Tasks />
          </CommonSuspense>
        ),
      },
      {
        path: "mercy",
        element: (
          <GoogleReCaptchaProvider reCaptchaKey={recaptchaSiteKey}>
            <Mercy />
          </GoogleReCaptchaProvider>
        ),
      },
      {
        path: "patches",
        element: (
          <GoogleReCaptchaProvider reCaptchaKey={recaptchaSiteKey}>
            <Mercy />
          </GoogleReCaptchaProvider>
        ),
      },
      {
        path: "super-patches-mercy",
        element: (
          <GoogleReCaptchaProvider reCaptchaKey={recaptchaSiteKey}>
            <Mercy />
          </GoogleReCaptchaProvider>
        ),
      },
      {
        path: "*",
        element: (
          <CommonSuspense>
            <NotFound />
          </CommonSuspense>
        ),
      },
    ],
  },
]);
