import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LocationPart } from "../components/CartographyCrafter/components/Location/LocationPart";
import {
  defaultBorderPaddingScalar,
  defaultBorderRadius,
  defaultFontSizeScalar,
  defaultMapZoom,
} from "../components/CartographyCrafter/components/OutputMapFeatures/FeatureBounds";
import { CustomMapPoint } from "../components/CartographyCrafter/components/OutputMapFeatures/PointEditor/CustomMapPoint";
import { ThemeIcon } from "../components/CartographyCrafter/components/ThemeIcon/ThemeIcon";

export const defaultCustomMapPoint: CustomMapPoint = {
  index: 0,
  byAddress: {
    address: "",
  },
};

export const mapStateSchema: Record<string, string> = {
  locationFieldContent: "string",
  zoom: "number",
  borderRadius: "number",
  showCoordinates: "boolean",
  dominantColorsLeftToRight: "boolean",
  showDominantColors: "boolean",
  fontScalar: "number",
  borderPaddingScalar: "number",
  mapTheme: "string",
  fontFamily: "string",
  storedLocationParts: "object",
  latitude: "number",
  longitude: "number",
  coordinateFormat: "string",
  filter: "string",
  darkMode: "boolean",
  invertMapColors: "boolean",
  customMapPoints: "object",
};

export interface MapState {
  locationFieldContent: string;
  rawStaticMap: string;
  zoom: number;
  borderRadius: number;
  showCoordinates: boolean;
  dominantColorsLeftToRight: boolean;
  showDominantColors: boolean;
  fontScalar: number;
  borderPaddingScalar: number;
  mapTheme: string;
  fontFamily: string;
  storedLocationParts: LocationPart[];
  latitude: number;
  longitude: number;
  coordinateFormat: string;
  filter: string;
  darkMode: boolean;
  invertMapColors: boolean;
  customMapPoints: CustomMapPoint[];
}

const initialState: MapState = {
  locationFieldContent: "",
  rawStaticMap: "",
  zoom: defaultMapZoom,
  borderRadius: defaultBorderRadius,
  showCoordinates: true,
  showDominantColors: true,
  fontScalar: defaultFontSizeScalar,
  borderPaddingScalar: defaultBorderPaddingScalar,
  dominantColorsLeftToRight: true,
  mapTheme: ThemeIcon.Grayscale,
  fontFamily: "Oswald",
  storedLocationParts: [],
  latitude: 0,
  longitude: 0,
  coordinateFormat: "Lat/Long",
  filter: "",
  darkMode: false,
  invertMapColors: false,
  customMapPoints: [defaultCustomMapPoint],
};

export const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    setLocationFieldContent: (state, action: PayloadAction<string>) => {
      state.locationFieldContent = action.payload;
    },
    setRawStaticMap: (state, action: PayloadAction<string>) => {
      state.rawStaticMap = action.payload;
    },
    setZoom: (state, action: PayloadAction<number>) => {
      state.zoom = action.payload;
    },
    setBorderRadius: (state, action: PayloadAction<number>) => {
      state.borderRadius = action.payload;
    },
    setShowCoordinates: (state, action: PayloadAction<boolean>) => {
      state.showCoordinates = action.payload;
    },
    setShowDominantColors: (state, action: PayloadAction<boolean>) => {
      state.showDominantColors = action.payload;
    },
    setDominantColorsLeftToRight: (state, action: PayloadAction<boolean>) => {
      state.dominantColorsLeftToRight = action.payload;
    },
    setFontScalar: (state, action: PayloadAction<number>) => {
      state.fontScalar = action.payload;
    },
    setBorderPaddingScalar: (state, action: PayloadAction<number>) => {
      state.borderPaddingScalar = action.payload;
    },
    setMapTheme: (state, action: PayloadAction<string>) => {
      state.mapTheme = action.payload;
    },
    setFontFamily: (state, action: PayloadAction<string>) => {
      state.fontFamily = action.payload;
    },
    setStoredLocationParts: (state, action: PayloadAction<LocationPart[]>) => {
      state.storedLocationParts = action.payload;
    },
    setLatitude: (state, action: PayloadAction<number>) => {
      state.latitude = action.payload;
    },
    setLongitude: (state, action: PayloadAction<number>) => {
      state.longitude = action.payload;
    },
    setCoordinateFormat: (state, action: PayloadAction<string>) => {
      state.coordinateFormat = action.payload;
    },
    setFilter: (state, action: PayloadAction<string>) => {
      state.filter = action.payload;
    },
    setDarkmode: (state, action: PayloadAction<boolean>) => {
      state.darkMode = action.payload;
    },
    setInvertMapColors: (state, action: PayloadAction<boolean>) => {
      state.invertMapColors = action.payload;
    },
    setCustomMapPoints: (state, action: PayloadAction<CustomMapPoint[]>) => {
      state.customMapPoints = action.payload;
    },
    setSlice: (state, action: PayloadAction<MapState>) => {
      return action.payload;
    },
    resetMapSlice: () => initialState,
  },
});

export const {
  setLocationFieldContent,
  setRawStaticMap,
  setZoom,
  setBorderRadius,
  setShowCoordinates,
  setShowDominantColors,
  setFontScalar,
  setBorderPaddingScalar,
  setMapTheme,
  resetMapSlice,
  setFontFamily,
  setStoredLocationParts,
  setLatitude,
  setLongitude,
  setCoordinateFormat,
  setDominantColorsLeftToRight,
  setFilter,
  setDarkmode,
  setInvertMapColors,
  setCustomMapPoints,
  setSlice,
} = mapSlice.actions;
export default mapSlice.reducer;
