import { Box } from "@mui/material";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { resetAllSlices } from "../../components/Debug/ResetRedux/ReduxHelpers";
import NotFoundImage from "../../components/NotFound/NotFoundImage";
import { useAppDispatch } from "../../redux/ReduxHooks";
import CommonButton from "../../shared/Components/Buttons/CommonButton";
import CommonSubSubHeader from "../../shared/Components/Text/CommonSubSubHeader";
import { reloadFonts } from "../Setup/FontHelpers";
import CommonDescription from "../../shared/Components/Text/CommonDescription";

export default function DefaultErrorBoundary() {
  // Ensure fonts are loaded
  useEffect(() => reloadFonts(), []);

  // Redux
  const dispatch = useAppDispatch();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <NotFoundImage />
      <CommonSubSubHeader>An error has occured.</CommonSubSubHeader>
      <CommonDescription textAlign="center">
        We think this might be a problem with Redux. Click the reset button
        below to reset the Redux store and reload the page.
      </CommonDescription>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          marginTop: "20px",
        }}
      >
        <Link to="/home">
          <CommonButton text={"Go home"} width="200px" />
        </Link>
        <CommonButton
          text={"Attempt fix"}
          width="200px"
          onClick={() => {
            resetAllSlices(dispatch);
            window.location.reload();
          }}
        />
      </Box>
    </Box>
  );
}
