import { Box, Button, SwipeableDrawer, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { offWhite, orange } from "../../shared/Constants/Colors";
import { useScrollPositionContext } from "../../shared/Context/ScrollPositionContext";
import { useMinWidth } from "../../shared/Hooks/useMinWidth";
import CommitHashLink from "./CommitHashLink";
import MenuButton from "./MenuButton";
import { menuItems } from "./MenuItems";
import { navbarColor, navbarHeight } from "./NavbarConstants";

export default function Navbar() {
  // Constants
  const scrollbarWidth = 8;

  // Hooks
  const canShowFullMenu = useMinWidth(1000);
  const location = useLocation();

  // Context
  const { resetScrollPosition } = useScrollPositionContext();

  // State
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  // Effects
  useEffect(() => setMenuOpen(false), [location]);

  const nameSignature = (
    <Link to={"/home"}>
      <Typography
        onClick={() => {
          if (location.pathname === "/home") resetScrollPosition();
        }}
        sx={{
          transition: "all 0.15s linear",
          "&:hover": {
            color: offWhite,
          },
          background: orange,
          userSelect: "none",
          backgroundClip: "text",
          color: "transparent",
          fontFamily: "Allison",
          fontWeight: "Bold",
          fontSize: "46px",
        }}
      >
        NathanCheshire.com
      </Typography>
    </Link>
  );

  if (canShowFullMenu)
    return (
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1000,
          height: `${navbarHeight}px`,
          backgroundColor: navbarColor,
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingX: "20px",
        }}
      >
        {nameSignature}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            gap: "5px",
            maxWidth: "100%",
          }}
        >
          {menuItems
            .filter((item) => !item.hidden)
            .map((menuItem) => {
              const onThis = location.pathname.includes(menuItem.urlPath);

              return (
                <Link to={menuItem.urlPath} key={menuItem.urlPath}>
                  <Button
                    size="small"
                    sx={{
                      color: onThis ? orange : "white",
                      backgroundColor: onThis ? "black" : "inherit",
                      "&:hover": {
                        backgroundColor: "black",
                      },
                      fontFamily: "Kanit",
                      fontWeight: "bold",
                      fontSize: "16px",
                      textTransform: "none",
                      paddingX: "15px",
                      borderRadius: "8px",
                    }}
                  >
                    {menuItem.label}
                  </Button>
                </Link>
              );
            })}
        </Box>
      </Box>
    );
  else
    return (
      <Box
        sx={{
          top: 0,
          zIndex: 1000,
          height: `${navbarHeight}px`,
          backgroundColor: navbarColor,
          width: "100%",
          display: "flex",
          gap: "10px",
          alignItems: "center",
          justifyContent: "center",
          paddingX: "20px",
        }}
      >
        <SwipeableDrawer
          anchor="right"
          onOpen={() => setMenuOpen(true)}
          onClose={() => setMenuOpen(false)}
          disableDiscovery={false}
          disableSwipeToOpen={false}
          open={menuOpen}
          PaperProps={{
            sx: {
              background: navbarColor,
              backdropFilter: "blur(8px)",
              "&::-webkit-scrollbar": {
                width: `${scrollbarWidth}px`,
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "white",
                borderRadius: `${scrollbarWidth / 2}px`,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
              },
              scrollbarWidth: "thin",
              scrollbarColor: `${"white"} transparent`,
            },
          }}
        >
          <Box
            sx={{
              paddingLeft: "15px",
              paddingTop: "15px",
              display: "flex",
            }}
          >
            <MenuButton toggled={true} setToggled={() => setMenuOpen(false)} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              paddingY: "10px",
            }}
          >
            <Box
              sx={{
                width: "80%",
                height: "4px",
                backgroundColor: "black",
                borderRadius: "12px",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              margin: "0 auto",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "start",
                  width: "100%",
                  height: "100%",
                  paddingBottom: "20px",
                  paddingX: "10px",
                  gap: "5px",
                  minWidth: "200px",
                }}
              >
                {menuItems
                  .filter((item) => !item.hidden)
                  .map((menuItem) => {
                    const onThis = location.pathname.includes(menuItem.urlPath);

                    return (
                      <Link
                        to={menuItem.urlPath}
                        key={menuItem.urlPath}
                        style={{
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        <Button
                          fullWidth
                          size="small"
                          sx={{
                            color: onThis ? orange : "white",
                            backgroundColor: onThis ? "black" : "auto",
                            fontFamily: "Kanit",
                            fontWeight: "bold",
                            fontSize: "16px",
                            textTransform: "none",
                            width: "100%",
                            borderRadius: "8px",
                            height: "38px",
                            transition: "all 0.15s linear",
                            "&:hover": {
                              backgroundColor: onThis ? "auto" : "black",
                            },
                          }}
                        >
                          {menuItem.label}
                        </Button>
                      </Link>
                    );
                  })}
              </Box>
              <CommitHashLink />
            </Box>
          </Box>
        </SwipeableDrawer>
        {nameSignature}
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "end",
            transition: "all 0.15s linear",
            zIndex: 8000000,
          }}
        >
          <MenuButton toggled={false} setToggled={() => setMenuOpen(true)} />
        </Box>
      </Box>
    );
}
