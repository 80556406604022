import { Checkbox, FormControlLabel } from "@mui/material";
import { capitalizeWords } from "../../../shared/Helpers/GeneralHelpers";
import { BooleanTag } from "../BooleanTag";

interface Props {
  booleanTag: BooleanTag;
  setSliceChecked: (name: string, checked: boolean) => void;
}

export default function ResetSliceCheckbox({
  booleanTag,
  setSliceChecked,
}: Props) {
  return (
    <FormControlLabel
      sx={{
        userSelect: "none",
      }}
      control={
        <Checkbox
          checked={booleanTag.checked}
          onChange={(event) => {
            const newState = event.target.checked;
            setSliceChecked(booleanTag.name, newState);
          }}
        />
      }
      label={capitalizeWords(booleanTag.name.replace(/([A-Z])/g, " $1").trim())}
    />
  );
}
