import {
  euclideanColorDistance,
  invertHexColor,
  rgbToHex,
} from "../../components/CartographyCrafter/helpers/color/ColorHelpers";
import CanvasAndContext from "../../components/CartographyCrafter/helpers/image/CanvasAndContext";

/**
 * A computation class for computing the top colors
 * of an image provided in base 64 string format.
 */
export class TopColorComputer {
  private static readonly DEFAULT_NUM_TOP_COLORS = 5;
  private static readonly DEFAULT_STARTING_THRESHOLD = 80;
  private static readonly DEFAULT_THRESHOLD_STEP = 10;

  private base64Image: string;
  private numTopColors: number = TopColorComputer.DEFAULT_NUM_TOP_COLORS;
  private startingThreshold: number =
    TopColorComputer.DEFAULT_STARTING_THRESHOLD;
  private thresholdStep: number = TopColorComputer.DEFAULT_THRESHOLD_STEP;
  private invertImageColors: boolean = false;

  /**
   * Constructs a new TopColorComputer.
   *
   * @param base64Image the base64Image string this computer will use
   */
  constructor(base64Image: string) {
    this.base64Image = base64Image;
  }

  /**
   * Sets the top color computation difference threshold used to compute
   * the number of top colors based on how many different colors there are in the image.
   *
   * @param startingThreshold the threshold
   * @returns this TopColorComputer
   */
  public setStartingThreshold(startingThreshold: number): TopColorComputer {
    this.startingThreshold = startingThreshold;
    return this;
  }

  /**
   * Sets whether to invert the image colors.
   *
   * @param invertImageColors whether to invert the image colors
   * @returns this TopColorComputer
   */
  public setInvertImageColors(invertImageColors: boolean): TopColorComputer {
    this.invertImageColors = invertImageColors;
    return this;
  }

  /**
   * Computes the top colors as defined by the members of this class.
   * 
   * @returns the top colors as defined by the members of this class
   */
  public computeTopColors(): Promise<string[]> {
    return new Promise((resolve, reject) => {
      const image = this.constructImage();

      image.onload = () => {
        const imageData = this.getImageData(image);

        const colorCount = new Map<string, number>();
        for (let i = 0; i < imageData.length; i += 4) {
          const hexColor = rgbToHex(
            imageData[i],
            imageData[i + 1],
            imageData[i + 2]
          );
          colorCount.set(hexColor, (colorCount.get(hexColor) || 0) + 1);
        }

        let sortedColors = Array.from(colorCount.entries())
          .sort((a, b) => b[1] - a[1])
          // It's a map of the hex to the count so we need to just get the hex code
          .map((entry) => entry[0]);

        const distinctColors = this.getTopColors(sortedColors);

        if (this.invertImageColors) {
          for (let i = 0; i < distinctColors.length; i++) {
            distinctColors[i] = invertHexColor(distinctColors[i]);
          }
        }

        resolve(distinctColors);
      };

      image.onerror = (error) => reject(error);
    });
  }

  private constructImage() {
    const image = new Image();
    image.src = this.base64Image;
    return image;
  }

  private getImageData(image: any): Uint8ClampedArray {
    const context = new CanvasAndContext({
      width: image.width,
      height: image.height,
    }).getContext();

    context.drawImage(image, 0, 0);
    return context.getImageData(
      0,
      0,
      image.width,
      image.height
    ).data;
  }

  private getTopColors(colors: string[]): string[] {
    let threshold = this.startingThreshold;

    while (threshold >= this.thresholdStep) {
      const filteredColors = this.getDistinctTopColorsWithThreshold(
        colors,
        threshold
      );

      if (filteredColors.length !== this.numTopColors) {
        threshold -= this.thresholdStep;
      } else {
        return filteredColors;
      }
    }

    return this.getDistinctTopColorsWithThreshold(colors, 0);
  }

  private getDistinctTopColorsWithThreshold(
    colors: string[],
    threshold: number
  ): string[] {
    let distinctColors: string[] = [];

    for (const color of colors) {
      if (distinctColors.length >= this.numTopColors) break;

      let isDistinct = true;
      for (const distinctColor of distinctColors) {
        if (euclideanColorDistance(color, distinctColor) < threshold) {
          isDistinct = false;
          break;
        }
      }

      if (isDistinct) distinctColors.push(color);
    }

    return distinctColors;
  }
}
