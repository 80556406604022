import { GenericStepperStep } from "../../../../../shared/Components/Stepper/GenericStepperStep";

export const mapStepperSteps: GenericStepperStep[] = [
  {
    name: "search places",
    index: 0,
  },
  {
    name: "features",
    index: 1,
  },
  {
    name: "download",
    index: 2,
  },
];
