import { Suspense } from "react";
import CommonLoadingFallback from "../shared/Components/Fallback/CommonLoadingFallback";

interface Props {
  children?: React.ReactNode;
}

export default function CommonSuspense({ children }: Props) {
  return <Suspense fallback={<CommonLoadingFallback />}>{children}</Suspense>;
}
