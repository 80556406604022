import { Box } from "@mui/material";
import useScalableSizes from "../../shared/Hooks/useScalableSizes";

export default function NotFoundImage() {
  // Constants
  const largeScreenSize = 300;
  const smallScreenSize = 225;

  // Context
  const { isLargeScreen } = useScalableSizes();
  const imageSize = isLargeScreen ? largeScreenSize : smallScreenSize;

  return (
    <Box
      sx={{
        maxWidth: `${imageSize}px`,
        maxHeight: `${imageSize}px`,
        display: "flex",
        alignItems: "end",
        justifyContent: "center",
        userSelect: "none",
      }}
    >
      <img
        alt="Snoopy Upset"
        src={require("./SnoopyUpset.png")}
        style={{
          width: "60%",
          height: "auto",
          display: "block",
          maxWidth: "100%",
          userSelect: "none",
        }}
      />
    </Box>
  );
}
