import { ManagedModal } from "query-string-modals";
import ContactViaEmailModal from "../../components/Contact/ContactViaEmailModal";
import DebugModal from "../../components/Debug/DebugModal";
import ResetReduxModal from "../../components/Debug/ResetRedux/ResetReduxModal";
import RoutesModal from "../../components/Maps/DataControls/RoutesModal";

export const modals: ManagedModal[] = [
  {
    modalId: "reset-redux",
    component: <ResetReduxModal />,
  },
  {
    modalId: "routes",
    component: <RoutesModal />,
  },
  {
    modalId: "contact",
    component: <ContactViaEmailModal />,
  },
  {
    modalId: "debug",
    component: <DebugModal />,
  },
];
