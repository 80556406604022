import {
  seScreenMinWidth,
  largeScreenMinWidth,
  mediumScreenMinWidth,
} from "../Constants/CommonConstants";
import { useMinWidth } from "./useMinWidth";

/**
 * Returns the provided common constant sizes used throughout this website:
 *
 * The text size of description text components
 * The text size of header text components
 * The width of the primary container
 * Whether the screen width is deemed large
 * Whether the screen width is deemed larger than an iPhone SE
 * Whether the screen width is deemed medium
 * The font size of the header for modals
 */
export default function useScalableSizes() {
  const isLargeScreen = useMinWidth(largeScreenMinWidth);
  const isMediumScreen = useMinWidth(mediumScreenMinWidth);
  const isLargerThanSe = useMinWidth(seScreenMinWidth);

  const descriptionTextSize = isLargeScreen ? "15px" : "14px";
  const headerTextSize = isLargeScreen ? "38px" : "32px";
  const subHeaderTextSize = isLargeScreen ? "26px" : "22px";
  const subSubHeaderTextSize = isLargeScreen ? "22px" : "20px";
  const primaryContainerWidth = isLargeScreen ? "69%" : "90%";
  const modalHeaderFontSize = isLargeScreen ? "46px" : "34px";
  const commonContainerSize = useMinWidth(480) ? "300px" : "200px";

  return {
    subHeaderTextSize,
    subSubHeaderTextSize,
    descriptionTextSize,
    headerTextSize,
    primaryContainerWidth,
    isLargeScreen,
    isLargerThanSe,
    isMediumScreen,
    modalHeaderFontSize,
    commonContainerSize,
  };
}
