import { Button } from "@mui/material";
import { evenMoreOffBlack } from "../../Constants/Colors";

interface Props {
  name: string;
  onClick?: () => void;
}

export default function TextButton({ name, onClick }: Props) {
  return (
    <Button
      onClick={onClick}
      sx={{
        userSelect: "none",
        fontFamily: "Roboto",
        fontWeight: "bold",
        fontSize: "16px",
        color: evenMoreOffBlack,
        whiteSpace: "nowrap",
        borderRadius: "200px",
        paddingX: "15px",
        textTransform: "none",
      }}
    >
      {name}
    </Button>
  );
}
