export interface CustomMapPoint {
  index: number;
  label?: string;
  byAddress?: {
    address: string;
  };
  byCoordinates?: {
    latitude: number;
    longitude: number;
  };
}

export enum CustomMapPointBy {
  Coordinates = "coordinates",
  Address = "address",
}

export function getMapPointLabel(mapPoint: CustomMapPoint) {
  if (mapPoint.label) return mapPoint.label;
  if (mapPoint.byAddress) return mapPoint.byAddress.address;
  if (mapPoint.byCoordinates)
    return `${mapPoint.byCoordinates.latitude},${mapPoint.byCoordinates.longitude}`;
  return "";
}

export function mapPointIsValid(mapPoint: CustomMapPoint) {
  return (
    mapPoint.byAddress?.address ||
    (mapPoint.byCoordinates?.latitude && mapPoint.byCoordinates?.longitude)
  );
}
