import { Loader } from "@googlemaps/js-api-loader";
import { GOOGLE_GEOLOCATOR_API_KEY } from "../../../../shared/Constants/GoogleKeys";
import {
  CustomMapPoint,
  mapPointIsValid,
} from "../../components/OutputMapFeatures/PointEditor/CustomMapPoint";
import { GeocoderStatus } from "./GeocoderStatus";
import { MapType } from "./MapType";
import { Scale } from "./Scale";
import { GoogleLocation } from "./interfaces/GoogleLocation";
import { PlacePrediction } from "./interfaces/PlacePrediction";

const MAPS_API_HEADER = "https://maps.googleapis.com/maps/api/staticmap";
const DEFAULT_DECIMAL_PRECISION_COORDINATES = 7;
const LIBRARY_NAMES = ["geocoding", "places"];
const loader = new Loader({
  apiKey: GOOGLE_GEOLOCATOR_API_KEY,
});

/**
 * Loads the Google Maps API, specifically the Geocoding API.
 *
 * @returns a promise that resolves to a boolean indicating whether the load was successful
 */
export async function loadGoogleMapsApis(): Promise<boolean> {
  const loadPromises = LIBRARY_NAMES.map((libraryName) =>
    (loader as any)
      .importLibrary(libraryName)
      .then(() => true)
      .catch((error: any) => {
        console.error(error);
        return false;
      })
  );

  const results = await Promise.all(loadPromises);
  return results.every((result) => result);
}

export function getPlacePredictions(
  locationText: string
): Promise<PlacePrediction[]> {
  return new Promise(async (resolve) => {
    const service = new google.maps.places.AutocompleteService();
    try {
      const response = await service.getPlacePredictions({
        input: locationText,
      });
      resolve(
        response.predictions.map((prediction) => {
          const substrings = prediction.matched_substrings;
          return {
            description: prediction.description,
            matchedSubstrings: substrings,
          };
        })
      );
    } catch (error) {
      console.error(error);
      resolve([]);
    }
  });
}

/**
 * Returns a Location object for the provided location information provided by a user.
 *
 * @param locationName the user provided location information
 * @param decimalPrecision the number of decimal points the location's latitude and longitude should be rounded to
 * @returns a Location object
 */
export function getLocation(
  locationName: string,
  decimalPrecision: number = DEFAULT_DECIMAL_PRECISION_COORDINATES
): Promise<GoogleLocation> {
  return new Promise((resolve, reject) => {
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({ address: locationName }, (results, status) => {
      if (status !== GeocoderStatus.OK) {
        reject(status);
        return;
      }

      if (!results || results.length === 0) {
        reject(GeocoderStatus.ZERO_RESULTS);
        return;
      }

      const place = results[0];
      const addressComponents = place.address_components.map(
        (addressComponent) => {
          return {
            longName: addressComponent.long_name,
            shortName: addressComponent.short_name,
          };
        }
      );
      const address = place.geometry;
      const location = address.location;
      const lat = location.lat();
      const lon = location.lng();
      const roundedLat = parseFloat(lat.toFixed(decimalPrecision));
      const roundedLon = parseFloat(lon.toFixed(decimalPrecision));

      const chosenLocation = {
        location: place.formatted_address,
        latitude: roundedLat,
        longitude: roundedLon,
        addressParts: addressComponents,
      };

      resolve(chosenLocation);
    });
  });
}

/**
 * Constructs and returns a URL for obtaining a static image with the provided parameters.
 *
 * @param latitude the latitude of the location
 * @param longitude the longitude of the location
 * @param zoom the zoom level
 * @param outputType the image output type
 * @param width the image width
 * @param height the image height
 * @param mapTypeStyling the url parameter styling for the map
 * @param mapType the type of the iamge
 * @param scale the scale of the image, either 1 or 2
 * @param customMapPoints the custom map points to annotate with a numbered location balloon.
 * @returns the url to obtain the staticd map image
 */
export function constructGetStaticMapUrl(
  latitude: number,
  longitude: number,
  zoom: number,
  outputType: string,
  width: number,
  height: number,
  mapTypeStyling: string,
  mapType: MapType = MapType.Roadmap,
  scale: Scale = Scale.ONE,
  customMapPoints: CustomMapPoint[] = []
) {
  const center = `${latitude},${longitude}`;

  const markers = [...customMapPoints]
    .sort((a, b) => a.index - b.index)
    .filter((point) => mapPointIsValid(point))
    .map((point, index) => {
      let location = "";

      if (point.byCoordinates) {
        location = `${point.byCoordinates.latitude},${point.byCoordinates.longitude}`;
      } else if (point.byAddress) {
        location = `${encodeURIComponent(point.byAddress.address)}`;
      }

      const mapLabel = index + 1;
      return `&markers=color:0x0F0F0F%7Clabel:${mapLabel}%7C${location}`;
    })
    .join("");

  return `${MAPS_API_HEADER}?key=${GOOGLE_GEOLOCATOR_API_KEY}&center=${center}&zoom=${zoom}&format=${outputType.toLowerCase()}&maptype=${mapType.toLowerCase()}&size=${width}x${height}${mapTypeStyling}&scale=${scale}${markers}`;
}
