import emailjs from "@emailjs/browser";
import { useEffect } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import {
  getLocalTimezone,
  getUserAgent,
} from "../../components/Debug/DebugUtils";
import CommonRouteContainer from "../../shared/Components/Container/Route/CommonRouteContainer";
import CommonHeader from "../../shared/Components/Text/CommonHeader";
import {
  emailJsPublicKey,
  emailJsServiceId,
  emailJsTemplateId,
} from "../../shared/Constants/EmailJsConstants";

export default function Mercy() {
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    async function logLoad() {
      if (!executeRecaptcha) return;
      const gcaptcha = await executeRecaptcha();

      const params = {
        from_name: "the website",
        email: "not sure",
        message: `The page was loaded timezone: ${getLocalTimezone()}, user agent: ${getUserAgent()}`,
        "g-recaptcha-response": gcaptcha,
      };

      emailjs
        .send(emailJsServiceId, emailJsTemplateId, params, emailJsPublicKey)
        .catch((_ignored: any) => {
          console.error(_ignored);
        });
    }

    logLoad();
  }, [executeRecaptcha]);

  return (
    <CommonRouteContainer titlePrefix="I love you">
      <CommonHeader>I miss you, I love you</CommonHeader>
      <iframe
        style={{
          borderRadius: "12px",
        }}
        src="https://open.spotify.com/embed/track/2tijppLc0gaWA2AfPDANvq?utm_source=generator"
        width="100%"
        height="352"
        frameBorder="0"
        title="Mercy"
        // @ts-expect-error valid syntax for js
        allowfullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      ></iframe>
    </CommonRouteContainer>
  );
}
