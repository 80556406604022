import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { evenMoreOffBlack } from "../../../shared/Constants/Colors";
import { getDevicePixelRatio } from "../../Debug/DebugUtils";
import UnselectableHelperText from "./UnselectableHelperText";
import UnselectableInputLabel from "./UnselectableInputLabel";

interface Props {
  labelText: string;
  value: string;
  setValue: (newValue: string) => void;
  getHelperText?: (currentValue: string) => string;
  showRequiredIfEmpty?: boolean;
  numLines?: number;
  disableClearable?: boolean;
  lineHeight?: number;
}

export default function ContactField({
  labelText,
  value,
  setValue,
  getHelperText,
  showRequiredIfEmpty = true,
  numLines = 1,
  disableClearable = false,
  lineHeight = 24,
}: Props) {
  // State
  const [fieldDirty, setFieldDirty] = useState<boolean>(false);
  const [focused, setFocused] = useState<boolean>(false);
  const [currentHeight, setCurrentHeight] = useState<number>(lineHeight);

  function superGetHelperText(): string {
    if (!fieldDirty) return "";
    if (showRequiredIfEmpty && value.length === 0)
      return labelText + " is required";
    return getHelperText ? getHelperText(value) : "";
  }

  useEffect(() => {
    if (focused) {
      if (currentHeight < numLines * lineHeight) {
        setCurrentHeight(currentHeight + getDevicePixelRatio());
      }
    } else {
      if (currentHeight > lineHeight) {
        setCurrentHeight(currentHeight - getDevicePixelRatio());
      }
    }
  }, [focused, currentHeight]);

  const inputFieldStyle = {
    "& .MuiInputBase-input::selection": {
      background: evenMoreOffBlack,
      color: evenMoreOffBlack,
    },
    ".MuiInputBase-inputMultiline": {
      height: `${currentHeight}px !important`,
    },
  };

  return (
    <Box
      key={labelText}
      width="100%"
      display="flex"
      alignItems="center"
      flexDirection="row"
      justifyContent="center"
    >
      <TextField
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        fullWidth
        size="small"
        sx={inputFieldStyle}
        slotProps={{
          htmlInput: {
            spellCheck: false,
          },
          input: {
            endAdornment: (
              <InputAdornment position="end">
                {value.length > 0 && !disableClearable && (
                  <IconButton onClick={() => setValue("")} edge="end">
                    <CloseIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          },
        }}
        label={<UnselectableInputLabel>{labelText}</UnselectableInputLabel>}
        multiline={numLines > 1}
        value={value}
        onChange={(e: any) => {
          setValue(e.target.value);
          setFieldDirty(true);
        }}
        helperText={
          <UnselectableHelperText>
            {superGetHelperText()}
          </UnselectableHelperText>
        }
      />
    </Box>
  );
}
