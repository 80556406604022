import { keyframes } from "@emotion/react";
import { Box, Slide } from "@mui/material";
import { ReactNode } from "react";
import { useInView } from "react-intersection-observer";

interface Props {
  children?: ReactNode;
  justification?: "center" | "start" | "end";
  maxWidth?: string;
  customBoxJustification?: string;
  gap?: string;
}

export default function SectionContainer({
  children,
  justification = "start",
  maxWidth = "650px",
  customBoxJustification = undefined,
  gap = "0px",
}: Props) {
  const lengthPercent = 75;
  const borderLen = 10;
  const borderRadius = 2 * borderLen;
  const borderColor = "#FBAE3CCF";

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.15,
  });

  // Left side animation
  const animateBorderLeft = keyframes`
    0% {
      width: 0;
      height: 0;
    }
    100% {
      width: calc(${lengthPercent}% + ${borderLen}px);
      height: calc(${lengthPercent}% + ${borderLen}px);
    }
  `;

  // Right side animation
  const animateBorderRight = keyframes`
    0% {
      width: 0;
      height: 0;
    }
    100% {
      width: calc(${lengthPercent}% + ${borderLen}px);
      height: calc(${lengthPercent}% + ${borderLen}px);
    }
  `;

  // Center animations
  const animateBorderCenterLeft = keyframes`
    0% {
      width: 0;
      height: 0;
      left: 50%;
      top: 0;
    }
    50% {
      width: calc(50% + ${borderLen}px);
      height: 0;
      left: -${borderLen}px;
      top: -${borderLen}px;
    }
    100% {
      width: calc(50% + ${borderLen}px);
      height: calc(${lengthPercent}% + ${borderLen}px);
      left: -${borderLen}px;
      top: -${borderLen}px;
    }
  `;

  const animateBorderCenterRight = keyframes`
    0% {
      width: 0;
      height: 0;
      right: 50%;
      top: 0;
    }
    50% {
      width: calc(50% + ${borderLen}px);
      height: 0;
      right: -${borderLen}px;
      top: -${borderLen}px;
    }
    100% {
      width: calc(50% + ${borderLen}px);
      height: calc(${lengthPercent}% + ${borderLen}px);
      right: -${borderLen}px;
      top: -${borderLen}px;
    }
  `;

  const sharedBorderProps = {
    content: '""',
    position: "absolute",
    pointerEvents: "none",
  };

  const leftJustificationBorder = {
    ...sharedBorderProps,
    top: -borderLen,
    left: -borderLen,
    width: 0,
    height: 0,
    borderLeft: `${borderLen}px solid ${borderColor}`,
    borderTop: `${borderLen}px solid ${borderColor}`,
    borderTopLeftRadius: `${borderRadius * 1.5}px`,
    borderBottomLeftRadius: `${borderRadius / 2}px`,
    borderTopRightRadius: `${borderRadius / 2}px`,
    animation: inView ? `${animateBorderLeft} 1s ease forwards` : "none",
  };

  const centerJustificationBorderLeft = {
    ...sharedBorderProps,
    left: "50%",
    width: 0,
    height: 0,
    borderLeft: `${borderLen}px solid ${borderColor}`,
    borderTop: `${borderLen}px solid ${borderColor}`,
    borderTopLeftRadius: `${borderRadius * 1.5}px`,
    borderBottomLeftRadius: `${borderRadius / 2}px`,
    animation: inView ? `${animateBorderCenterLeft} 1s ease forwards` : "none",
  };

  const centerJustificationBorderRight = {
    ...sharedBorderProps,
    right: "50%",
    width: 0,
    height: 0,
    borderRight: `${borderLen}px solid ${borderColor}`,
    borderTop: `${borderLen}px solid ${borderColor}`,
    borderTopRightRadius: `${borderRadius * 1.5}px`,
    borderBottomRightRadius: `${borderRadius / 2}px`,
    animation: inView ? `${animateBorderCenterRight} 1s ease forwards` : "none",
  };

  const rightJustificationBorder = {
    ...sharedBorderProps,
    top: -borderLen,
    right: -borderLen,
    width: 0,
    height: 0,
    borderRight: `${borderLen}px solid ${borderColor}`,
    borderTop: `${borderLen}px solid ${borderColor}`,
    borderTopRightRadius: `${borderRadius * 1.5}px`,
    borderTopLeftRadius: `${borderRadius / 2}px`,
    borderBottomRightRadius: `${borderRadius / 2}px`,
    animation: inView ? `${animateBorderRight} 1s ease forwards` : "none",
  };

  function getJustificationBorders() {
    if (justification === "center") {
      return {
        "&::before": centerJustificationBorderLeft,
        "&::after": centerJustificationBorderRight,
      };
    } else if (justification === "start") {
      return {
        "&::before": leftJustificationBorder,
      };
    } else {
      return {
        "&::before": rightJustificationBorder,
      };
    }
  }

  function getBoxShadowStyling() {
    if (justification === "start") {
      return "12px 12px 12px rgba(0, 0, 0, 0.1), 0px 5px 10px #F0F0F0";
    } else if (justification === "end") {
      return "-12px 12px 12px rgba(0, 0, 0, 0.1), -5px 0px 10px #F0F0F0";
    } else {
      return "0px 12px 12px rgba(0, 0, 0, 0.1), 0px 5px 5px #F0F0F0";
    }
  }

  return (
    <Box
      ref={ref}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: customBoxJustification ?? justification,
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Slide direction="up" in={inView} timeout={500}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "center",
              padding: "25px",
              borderRadius: `${borderRadius}px`,
              boxShadow: getBoxShadowStyling(),
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              backdropFilter: "blur(10px)",
              maxWidth: maxWidth,
              position: "relative",
              ...getJustificationBorders(),
              gap: gap,
            }}
          >
            {children}
          </Box>
        </Slide>
      </Box>
    </Box>
  );
}
