import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ExportMapState {
  proposedFileName: string;
  proposedImage: string;
}

const initialState: ExportMapState = {
  proposedFileName: "",
  proposedImage: "",
};

export const exportMapSlice = createSlice({
  name: "exportMap",
  initialState,
  reducers: {
    setProposedFileName: (state, action: PayloadAction<string>) => {
      state.proposedFileName = action.payload;
    },
    setProposedImage: (state, action: PayloadAction<string>) => {
      state.proposedImage = action.payload;
    },
    resetExportMapSlice: () => initialState,
  },
});

export const { setProposedFileName, setProposedImage, resetExportMapSlice } =
  exportMapSlice.actions;
export default exportMapSlice.reducer;
