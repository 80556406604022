import styled from "@emotion/styled";

interface Props {
  children: React.ReactNode;
}

export default function UnselectableHelperText({
  children,
}: Props) {
  const UnselectableHelperText = styled("label")({
    userSelect: "none",
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
  });

  return <UnselectableHelperText>{children}</UnselectableHelperText>;
}
