import { Box, IconButton } from "@mui/material";
import { footerHeight } from "../Footer/FooterConstants";
import { commonBoxShadow } from "../../shared/Constants/StylingConstants";
import { offBlack, orange } from "../../shared/Constants/Colors";
import { Link } from "react-router-dom";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

interface Props {
  height: number;
  bottomOffset: number;
  buttonId?: string;
  linkTo?: string;
  rotationDegrees?: number;
  onClick?: () => void;
}

export default function NavigationArrow({
  height,
  bottomOffset,
  buttonId,
  linkTo,
  rotationDegrees = 0,
  onClick,
}: Props) {
  return (
    <Box
      sx={{
        position: "fixed",
        zIndex: 10,
        alignItems: "center",
        display: "flex",
        justifyContent: "end",
        marginX: "10px",
        borderRadius: "200px",
        gap: "20px",
        right: 10,
        bottom: `calc(${footerHeight}px / 2 - ${height}px / 2 - 2px + ${bottomOffset}px)`,
        opacity: 0.5,
        transition: "opacity 0.3s linear",
        "&:hover": {
          opacity: 1,
        },
      }}
      id={buttonId}
    >
      <Box
        sx={{
          backgroundColor: orange,
          borderRadius: "200px",
          boxShadow: commonBoxShadow,
        }}
      >
        {linkTo && (
          <Link to={linkTo}>
            <IconButton>
              <KeyboardArrowUpIcon
                sx={{
                  transform: `rotate(${rotationDegrees}deg)`,
                  color: offBlack,
                }}
              />
            </IconButton>
          </Link>
        )}
        {!linkTo && (
          <IconButton onClick={onClick}>
            <KeyboardArrowUpIcon
              sx={{
                transform: `rotate(${rotationDegrees}deg)`,
                color: offBlack,
              }}
            />
          </IconButton>
        )}
      </Box>
    </Box>
  );
}
