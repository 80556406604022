import { generalMaxWidthNumber } from "../../../Constants/CommonConstants";
import { useMinWidth } from "../../../Hooks/useMinWidth";
import {
  defaultFullPadding,
  defaultShortenedPadding,
} from "./CommonRouteContainerConstants";

export const useCommonRouteContainerVerticalPadding = (
  providedPaddingY?: string
) => {
  const showFullPadding = useMinWidth(generalMaxWidthNumber);

  if (providedPaddingY !== "determine") return providedPaddingY;

  return showFullPadding ? defaultFullPadding : defaultShortenedPadding;
};
