import { Box } from "@mui/material";
import { useModal } from "query-string-modals";
import { Link } from "react-router-dom";
import CommonButton from "../../shared/Components/Buttons/CommonButton";
import StandardModal from "../../shared/Components/Modal/StandardModal";

export default function FallbackModal() {
  // Context
  const { closeModal } = useModal();

  return (
    <StandardModal
      title={"Invalid modal ID"}
      cancelButtonText={"Close"}
      cancelButtonAction={() => closeModal()}
      descrpitionText="Cool, you have discovered the modal system I use for this website. This happens to be my library that I wrote which I am quite proud of. Since no modal exists with the ID in the URL, this is the app-wide fallback modal. If you would like to learn more about this library, check out the blog on it."
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "30px",
        }}
      >
        <Link to="/blogs/query-string-modals">
          <CommonButton text={"Query string modals blog"} width="250px" />
        </Link>
      </Box>
    </StandardModal>
  );
}
