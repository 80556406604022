import { useEffect } from "react";
import { useAlert } from "../../shared/Context/AlertContext";

export default function SaveHotkeySuppressor() {
  // Constants
  const keydown = "keydown";

  // Context
  const { showInfoAlert } = useAlert();

  // Effects
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.metaKey && event.key === "s") {
        event.preventDefault();
        showInfoAlert("Save hotkey suppressed");
      }
    };

    window.addEventListener(keydown, handleKeyDown);
    return () => window.removeEventListener(keydown, handleKeyDown);
  }, []);

  return <></>;
}
