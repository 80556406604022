import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { orange } from "../../shared/Constants/Colors";

interface Props {
  to: string;
  label: string;
}

export default function Crumb({ to, label }: Props) {
  return (
    <Link to={to} key={`breacrumb-link-to-${to}`}>
      <Button
        size="small"
        sx={{
          color: "white",
          fontFamily: "Kanit",
          fontWeight: "bold",
          fontSize: "12px",
          textTransform: "none",
          paddingX: "8px",
          borderRadius: "8px",
          "&:hover": {
            color: orange,
            backgroundColor: "black",
          },
        }}
      >
        {label}
      </Button>
    </Link>
  );
}
