import { Box } from "@mui/material";
import { useModal } from "query-string-modals";
import { useEffect, useState } from "react";
import { reloadFonts } from "../../app/Setup/FontHelpers";
import fonts from "../../assets/fonts/GoogleFontFamilies.json";
import CommonButton from "../../shared/Components/Buttons/CommonButton";
import StandardModal from "../../shared/Components/Modal/StandardModal";
import CommonDescription from "../../shared/Components/Text/CommonDescription";
import { onLocalHost } from "../../shared/Helpers/NetworkHelpers";
import {
  maxOpacity,
  minOpacity,
} from "../CartographyCrafter/helpers/color/OpacityConstants";
import DebugInformation from "./DebugInformation";
import {
  getBrowserLanguage,
  getDevicePixelRatio,
  getLocalTimezone,
  getUserAgent,
  likelyOnMobile,
} from "./DebugUtils";
import NetlifyButton from "./NetlifyButton";
import ReduxButton from "./ResetRedux/ReduxButton";

export default function DebugModal() {
  // Consts
  const googleFonts = fonts as string[];
  const reloadedFontsMessageDuration = 3000;

  // State
  const [reloadedFonts, setReloadedFonts] = useState<boolean>(false);

  // Context
  const { closeModal } = useModal();

  // Effects
  useEffect(() => {
    if (!reloadedFonts) return;

    setTimeout(() => {
      if (reloadedFonts) setReloadedFonts(false);
    }, reloadedFontsMessageDuration);
  }, [reloadedFonts]);

  function getFormattedWindowInnerDimensions() {
    return window.innerHeight + "x" + window.innerWidth;
  }

  function getFormattedWindowScreenDimensions() {
    return window.screen.width + "x" + window.screen.height;
  }

  function getOnMobileLabel() {
    if (likelyOnMobile()) return "Likely on mobile";
    else return "Not likely on mobile";
  }

  return (
    <StandardModal
      title={"Debug"}
      cancelButtonText={"Close"}
      cancelButtonAction={() => closeModal()}
    >
      <DebugInformation>
        {`Viewport: ${getFormattedWindowInnerDimensions()}`}
      </DebugInformation>
      <DebugInformation>{`Screen: ${getFormattedWindowScreenDimensions()}`}</DebugInformation>
      <DebugInformation>{`Pixel ratio: ${getDevicePixelRatio()}`}</DebugInformation>
      <DebugInformation>{`Language: ${getBrowserLanguage()}`}</DebugInformation>
      <DebugInformation>{`Timezone: ${getLocalTimezone()}`}</DebugInformation>
      <DebugInformation>
        {"On " + (onLocalHost() ? "localhost" : "production")}
      </DebugInformation>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        <CommonDescription textAlign="center">
          {getUserAgent() + ` (${getOnMobileLabel()})`}
        </CommonDescription>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "10px",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          marginTop: "10px",
        }}
      >
        <NetlifyButton />
        <ReduxButton />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "10px",
          gap: "10px",
        }}
      >
        <CommonButton
          fontSize="13px"
          fontFamily="Raleway"
          text="Reload fonts"
          width="200px"
          height="52px"
          onClick={() => {
            reloadFonts();
            setReloadedFonts(true);
          }}
        />
        <Box
          sx={{
            opacity: reloadedFonts ? maxOpacity : minOpacity,
            transition: "opacity 0.3s linear",
          }}
        >
          <CommonDescription>
            {`Reloaded ${googleFonts.length} fonts`}
          </CommonDescription>
        </Box>
      </Box>
    </StandardModal>
  );
}
