import getBuildHash from "./BuildHelpers";

interface DebugPrint {
  name: string;
  print: string;
}

const debugPrints: DebugPrint[] = [
  {
    name: "By",
    print: "Nate Cheshire",
  },
  {
    name: "Website build",
    print: `${getBuildHash()}`,
  },
];

export function printDebugs() {
  debugPrints.forEach((debug) =>
    console.debug(`${debug.name}: ${debug.print}`)
  );
}
