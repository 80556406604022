import { useModal } from "query-string-modals";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import StandardModal from "../../shared/Components/Modal/StandardModal";
import { recaptchaSiteKey } from "../../shared/Constants/GoogleKeys";
import ContactViaEmail from "./ContactViaEmail";

export default function ContactViaEmailModal() {
  const { closeModal } = useModal();

  return (
    <StandardModal
      title=""
      cancelButtonText={"Close"}
      cancelButtonAction={() => closeModal()}
    >
      <GoogleReCaptchaProvider reCaptchaKey={recaptchaSiteKey}>
        <ContactViaEmail onEmailSuccessfullySent={closeModal} />
      </GoogleReCaptchaProvider>
    </StandardModal>
  );
}
