import { useModal } from "query-string-modals";
import StandardModal from "../../../shared/Components/Modal/StandardModal";
import CommonDescription from "../../../shared/Components/Text/CommonDescription";
import CommonButton from "../../../shared/Components/Buttons/CommonButton";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";

export default function RoutesModal() {
  const { closeModal } = useModal();

  return (
    <StandardModal
      title={"Routes"}
      cancelButtonText={"Close"}
      cancelButtonAction={() => closeModal()}
    >
      <CommonDescription>
        Routes are paths from a particular student's Banner public address for a
        given semester to the university. You can toggle on and off specific
        states as well as search for specific students by netid. If you make an
        error by enabling too many states at one time such that the map
        continues to crash, see the debug page to reset the proper redux slice.
      </CommonDescription>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          marginTop: "40px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "200px",
          }}
        >
          <Link to="?modal=debug">
            <CommonButton text="Debug" />
          </Link>
        </Box>
      </Box>
    </StandardModal>
  );
}
