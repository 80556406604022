import { Button } from "@mui/material";
import { evenMoreOffBlack, offBlack, offWhite } from "../../Constants/Colors";

interface Props {
  text: string;
  disabled?: boolean;
  onClick?: () => void;
  width?: string;
  height?: string;
  backgroundColor?: string;
  hoverBackgroundColor?: string;
  textColor?: string;
  fontSize?: string;
  fontFamily?: string;
}

export default function CommonButton({
  text,
  disabled = false,
  onClick,
  width,
  backgroundColor = evenMoreOffBlack,
  hoverBackgroundColor = offBlack,
  textColor = offWhite,
  height,
  fontSize = "16px",
  fontFamily = "Roboto",
}: Props) {
  return (
    <Button
      disabled={disabled}
      fullWidth
      onClick={onClick}
      variant="contained"
      sx={{
        color: textColor,
        backgroundColor: backgroundColor,
        "&:hover": {
          backgroundColor: hoverBackgroundColor,
        },
        borderRadius: "200px",
        textTransform: "none",
        fontSize: fontSize,
        fontFamily: fontFamily,
        fontWeight: "bold",
        width: width,
        height: height,
      }}
    >
      {text}
    </Button>
  );
}
