export enum ThemeIcon {
  Aubergine = "aubergine",
  Dark = "dark",
  Retro = "retro",
  Silver = "silver",
  Grayscale = "grayscale",
  AssassinsCreed = "assassins creed",
  Halloween = "halloween",
  Artic = "artic",
  Vintage = "vintage",
}
