import { TopColorComputer } from "../../../../shared/Color/TopColorComputer";
import { offBlack, offWhite } from "../../../../shared/Constants/Colors";
import { bulletPoint } from "../../../../shared/Helpers/StringHelpers";
import {
  CustomMapPoint,
  getMapPointLabel,
  mapPointIsValid,
} from "../../components/OutputMapFeatures/PointEditor/CustomMapPoint";
import { sortHexColorsByLuminance } from "../color/ColorHelpers";
import CanvasAndContext from "./CanvasAndContext";

type AdditionalDrawMethod = (canvasAndContext: CanvasAndContext) => void;

// Changable by user
const DEFAULT_BORDER_PADDING_SCALER = 10;
const DEFAULT_TEXT_PADDING_SCALAR = 2;
const DEFAULT_FONT_SCALAR = 0.06;
const DEFAULT_LOCATION_FONT = "Oswald";
const DEFAULT_BORDER_RADIUS = 0;

// Currently not changable by user
const DEFAULT_COORDINATE_FONT = "Oswald";
const DEFAULT_LOCATION_PARTS_COLOR = offBlack;
const DEFAULT_COLOR_GAP = 15;
const DEFAULT_COLOR_BLOCK_SCALAR = 30;
const DEFAULT_IMAGE_COLOR_BLOCK_GAP = 25;
const DEFAULT_COORDINATE_COLOR = "#4F4F4F";
const DEFAULT_CUSTOM_MAP_POINTS_FONT = "Roboto";
const CUSTOM_MAP_POINTS_LINE_HEIGHT = 60;
const CUSTOM_MAP_POINTS_FONT_SCALAR = 0.35;

export class MapMarker {
  private locationImage: string;
  private locationParts: string[];
  private coordinates?: string;
  private showDominantColors: boolean;
  private borderPaddingScalar: number;
  private textPaddingScalar: number;
  private fontScalar: number;
  private backgroundColor: string;
  private locationPartsColor: string;
  private borderRadius: number;
  private locationFont: string;
  private dominantColorsThreshold: number;
  private dominantColorsLeftToRight: boolean;
  private additionalPaddingBelowImage: number;
  private filter: string;
  private darkmode: boolean;
  private invertImageColors: boolean;
  private customMapPoints?: CustomMapPoint[];

  private image?: HTMLImageElement;

  private additionalDrawMethods: AdditionalDrawMethod[];

  constructor(locationImage: string) {
    this.locationImage = locationImage;
    this.locationParts = [];
    this.showDominantColors = true;
    this.borderPaddingScalar = DEFAULT_BORDER_PADDING_SCALER;
    this.textPaddingScalar = DEFAULT_TEXT_PADDING_SCALAR;
    this.fontScalar = DEFAULT_FONT_SCALAR;
    this.backgroundColor = offWhite;
    this.locationPartsColor = DEFAULT_LOCATION_PARTS_COLOR;
    this.borderRadius = DEFAULT_BORDER_RADIUS;
    this.locationFont = DEFAULT_LOCATION_FONT;
    this.additionalDrawMethods = [];
    this.dominantColorsThreshold = 80;
    this.dominantColorsLeftToRight = true;
    this.additionalPaddingBelowImage = 0;
    this.filter = "";
    this.darkmode = false;
    this.invertImageColors = false;
  }

  addAdditionalDrawMethod(drawMethod: AdditionalDrawMethod) {
    this.additionalDrawMethods.push(drawMethod);
  }

  setInvertImageColors(invertImageColors: boolean) {
    this.invertImageColors = invertImageColors;
    return this;
  }

  setCustomMapPoints(points: CustomMapPoint[]) {
    this.customMapPoints = points;
    return this;
  }

  setDarkmode(darkmode: boolean) {
    if (darkmode) {
      this.backgroundColor = offBlack;
      this.locationPartsColor = offWhite;
    } else {
      this.backgroundColor = offWhite;
      this.locationPartsColor = offBlack;
    }

    this.darkmode = darkmode;
    return this;
  }

  setFilter(filter: string) {
    this.filter = filter;
    return this;
  }

  setAdditionalPaddingBelowImage(
    additionalPaddingBelowImage: number
  ): MapMarker {
    this.additionalPaddingBelowImage = additionalPaddingBelowImage;
    return this;
  }

  setDominantColorThreshold(dominantColorThreshold: number): MapMarker {
    this.dominantColorsThreshold = dominantColorThreshold;
    return this;
  }

  setBorderRadius(borderRadius: number): MapMarker {
    this.borderRadius = borderRadius;
    return this;
  }

  setCoordinates(coordinates: string): MapMarker {
    this.coordinates = coordinates;
    return this;
  }

  setLocationParts(locationParts: string[]): MapMarker {
    this.locationParts = locationParts;
    return this;
  }

  setShowDominantColors(showDominantColors: boolean): MapMarker {
    this.showDominantColors = showDominantColors;
    return this;
  }

  setDominantColorsLeftToRight(dominantColorsLeftToRight: boolean): MapMarker {
    this.dominantColorsLeftToRight = dominantColorsLeftToRight;
    return this;
  }

  setBorderPaddingScalar(borderPaddingScalar: number): MapMarker {
    this.borderPaddingScalar = borderPaddingScalar;
    return this;
  }

  setTextPaddingScalar(textPaddingScalar: number): MapMarker {
    this.textPaddingScalar = textPaddingScalar;
    return this;
  }

  setFontScalar(fontScalar: number): MapMarker {
    this.fontScalar = fontScalar;
    return this;
  }

  setLocationFont(fontFamily: string): MapMarker {
    this.locationFont = fontFamily;
    return this;
  }

  /**
   * Draws the dominant colors below the image.
   *
   * @param image the image
   * @param canvasAndContext the canvas and context
   * @returns the amount to increment the current Y tracking variable by
   */
  private async drawDominantColors(
    image: HTMLImageElement,
    canvasAndContext: CanvasAndContext
  ): Promise<number> {
    const computer = new TopColorComputer(this.locationImage)
      .setInvertImageColors(this.invertImageColors)
      .setStartingThreshold(this.dominantColorsThreshold);

    const topColors: string[] = await computer.computeTopColors();

    const luminancedColor = sortHexColorsByLuminance(topColors);
    if (!this.dominantColorsLeftToRight) {
      luminancedColor.reverse();
    }

    const totalPadding = DEFAULT_COLOR_GAP * (luminancedColor.length - 1);
    const availableWidth = image.width - totalPadding;
    const rectangleWidth = availableWidth / luminancedColor.length;

    const context = canvasAndContext.getContext();
    const borderPadding = image.width / this.borderPaddingScalar;
    const rectangleHeight = this.getDominantColorRectangleHeight();
    let currentX = borderPadding;
    luminancedColor.forEach((color: string) => {
      context.fillStyle = color;
      context.fillRect(
        currentX,
        image.height + borderPadding + DEFAULT_IMAGE_COLOR_BLOCK_GAP,
        rectangleWidth,
        rectangleHeight
      );

      currentX += rectangleWidth + DEFAULT_COLOR_GAP;
    });

    return rectangleHeight + DEFAULT_IMAGE_COLOR_BLOCK_GAP;
  }

  getDominantColorRectangleHeight() {
    return this.image!.height / DEFAULT_COLOR_BLOCK_SCALAR;
  }

  getBorderPadding() {
    return this.image!.width / this.borderPaddingScalar;
  }

  getFontSize() {
    return this.image!.width * this.fontScalar;
  }

  getTextPadding() {
    return this.getFontSize() * this.textPaddingScalar;
  }

  getAdditionalPaddingBelowImage() {
    return this.additionalPaddingBelowImage;
  }

  isDarkmode() {
    return this.darkmode;
  }

  private getDominantColorsHeight(): number {
    if (!this.showDominantColors) return 0;
    return this.getDominantColorRectangleHeight();
  }

  getDominantColorsBottom() {
    /*
     * border padding at top
     * image height below that
     * then color block gap
     * then colors themselves
     */

    return (
      this.getBorderPadding() +
      this.image!.height +
      DEFAULT_IMAGE_COLOR_BLOCK_GAP +
      this.getDominantColorsHeight()
    );
  }

  private setupAndGetCanvasAndContext() {
    const imageWidth = this.image!.width;
    const imageHeight = this.image!.height;

    const borderPadding = this.getBorderPadding();
    const fontSize = this.getFontSize();
    const textPadding = this.getTextPadding();

    const textHeight = fontSize * this.locationParts.length;
    const canvasWidth = imageWidth + borderPadding * 2;
    const canvasHeight =
      borderPadding + imageHeight + textPadding + textHeight + borderPadding;
    return new CanvasAndContext({
      width: canvasWidth,
      height: canvasHeight,
    });
  }

  private async drawImage(): Promise<string> {
    if (this.image === undefined) return "";

    const borderPadding = this.getBorderPadding();
    const fontSize = this.getFontSize();

    const canvasAndContext = this.setupAndGetCanvasAndContext();
    canvasAndContext.fillBackground(this.backgroundColor);
    canvasAndContext.drawRoundedImage(
      this.image,
      borderPadding,
      this.borderRadius,
      this.invertImageColors
    );

    let currentY = borderPadding + this.image.height + this.getTextPadding();
    if (this.showDominantColors) {
      const incrementYBy = await this.drawDominantColors(
        this.image,
        canvasAndContext
      );
      currentY += incrementYBy;
    }

    canvasAndContext.setFont(fontSize, this.locationFont, true);
    canvasAndContext.setFillStyle(this.locationPartsColor);
    canvasAndContext.drawAlignedStrings(this.locationParts, fontSize, currentY);
    currentY += Math.max(fontSize * (this.locationParts.length - 1), 0);

    // Coordinates
    if (this.coordinates) {
      canvasAndContext.setFont(fontSize / 2, DEFAULT_COORDINATE_FONT);
      canvasAndContext.setFillStyle(DEFAULT_COORDINATE_COLOR);
      const yToDrawAt = currentY + fontSize;
      canvasAndContext.drawAlignedString(this.coordinates, fontSize, yToDrawAt);
      currentY += fontSize;
    }

    currentY += fontSize;

    if (this.customMapPoints) {
      canvasAndContext.extendCanvasAndDrawCenteredWrappedText(
        [...this.customMapPoints]
          .sort((a, b) => a.index - b.index)
          .filter((point) => mapPointIsValid(point))
          .map((point, index) => `${index + 1}. ${getMapPointLabel(point)}`)
          .join(` ${bulletPoint} `),
        this.image.width,
        currentY,
        CUSTOM_MAP_POINTS_LINE_HEIGHT,
        10,
        this.getFontSize() * CUSTOM_MAP_POINTS_FONT_SCALAR,
        DEFAULT_CUSTOM_MAP_POINTS_FONT,
        this.isDarkmode()
      );
    }

    this.additionalDrawMethods.forEach((drawMethod) =>
      drawMethod(canvasAndContext)
    );

    if (this.filter) canvasAndContext.applyFilter(this.filter);
    return canvasAndContext.getBase64ImageString();
  }

  /**
   * Draws and returns the marked up poster.
   *
   * @returns the marked up poster in base64 string form
   */
  async draw(): Promise<string> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = this.locationImage;
      image.onload = async () => {
        this.image = image;
        resolve(this.drawImage());
      };
      image.onerror = (error) => reject(error);
    });
  }
}
