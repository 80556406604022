import { MenuItem } from "./MenuItem";

export function isHiddenRoute(pathname: string) {
  return !(
    menuItems.filter((menuItem) => pathname.startsWith(menuItem.urlPath))
      .length > 0
  );
}

/**
 * Returns the next menu item after the current one if applicable.
 *
 * @returns the next menu item after the current one if applicable
 */
export function nextMenuItem(currentPath: string): MenuItem | undefined {
  const index = menuItems.findIndex((item) =>
    item.urlPath.includes(currentPath)
  );

  if (index >= 0 && index + 1 < menuItems.length) {
    const item = menuItems[index + 1];
    if (item.hidden) return undefined;
    return item;
  }

  return undefined;
}

/**
 * Returns the last menu item before the current one if applicable.
 *
 * @returns the last menu item before the current one if applicable
 */
export function lastMenuItem(currentPath: string): MenuItem | undefined {
  const index = menuItems.findIndex((item) =>
    item.urlPath.includes(currentPath)
  );

  if (index > 0) {
    const item = menuItems[index - 1];
    if (item.hidden) return undefined;
    return item;
  }

  return undefined;
}

export const menuItems: MenuItem[] = [
  {
    label: "Home",
    urlPath: "/home",
  },
  {
    label: "Services",
    urlPath: "/services",
  },
  {
    label: "Widgets",
    urlPath: "/widgets",
  },
  {
    label: "Blogs",
    urlPath: "/blogs",
  },
  {
    label: "Contact",
    urlPath: "/contact",
  },
  {
    label: "GitHub",
    urlPath: "/github",
  },
  {
    label: "Employers",
    urlPath: "/for-employers",
    hidden: true,
  },
];
