import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const defaultSimulationSpeed = 100;

interface GameOfLifeState {
  simulationSpeed: number;
  drawMode: boolean;
  drawGridLines: boolean;
  detectOscillations: boolean;
}

const initialState: GameOfLifeState = {
  simulationSpeed: defaultSimulationSpeed,
  drawMode: true,
  drawGridLines: true,
  detectOscillations: true,
};

export const gameOfLifeSlice = createSlice({
  name: "gameOfLife",
  initialState,
  reducers: {
    setSimulationSpeed: (state, action: PayloadAction<number>) => {
      state.simulationSpeed = action.payload;
    },
    setDrawMode: (state, action: PayloadAction<boolean>) => {
      state.drawMode = action.payload;
    },
    setDrawGridLines: (state, action: PayloadAction<boolean>) => {
      state.drawGridLines = action.payload;
    },
    setDetectOscillations: (state, action: PayloadAction<boolean>) => {
      state.detectOscillations = action.payload;
    },
    resetGameOfLifeSlice: () => initialState,
  },
});

export const {
  setSimulationSpeed,
  setDrawMode,
  setDrawGridLines,
  setDetectOscillations,
  resetGameOfLifeSlice,
} = gameOfLifeSlice.actions;
export default gameOfLifeSlice.reducer;
